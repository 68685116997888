<template>
    <panel-alert class="panel-alert-record-cancel" title="撤销" width="480px" isShowBottom @onCloseClick="onCloseClick"
        @onConfirmClick="onConfirmClick">
        <template v-slot:content>
            <p>该记录撤销后，不能进行再次编辑。</p>
        </template>
    </panel-alert>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
export default {
    components: {
        PanelAlert,
    },


    methods: {
        onCloseClick() {
            this.$emit("onCloseClick");
        },

        onConfirmClick() {
            this.$emit("onConfirmClick");
        },
    },
};
</script>
  
<style lang="scss">
.panel-alert-record-cancel {
    .panel-alert-top {
        border-bottom: none;
    }
    
    .panel-alert-bottom {
        border-top: none;
        padding-right: 24px;

        &-confirm{
            background-color: #DF4747;
        }
    }
}
</style>