<template>
    <div class="weakday-selector">
        <div class="weakday-selector-content">
            <div class="weakday-selector-content-item" v-for="(item, index) of dataArray" :key="index"
                @click="onItemClick(index)">
                <img class="weakday-selector-content-item-icon" :src="item ? checkedIcon : unCheckedIcon" />
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            dataArray: [false, false, false, false, false, false, false],
            checkedIcon: require("@imgs/report-check-blue.png"),
            unCheckedIcon: require("@imgs/report-check-white.png"),
        };
    },

    emits: ["onSelectedWeekday"],

    methods: {
        // action
        onItemClick(index) {
            let tempDataArray = this.dataArray
            tempDataArray[index] = !tempDataArray[index]
            this.dataArray = [...tempDataArray]
            this.$emit("onSelectedWeekday", index, this.dataArray[index]);
        },

        setup(dataArray){
            this.dataArray = dataArray
        }
    },
};
</script>

<style lang="scss" scoped>
.weakday-selector {

    &-content {
        display: flex;
        align-content: center;
        margin-left: 28px;

        &-item {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            margin-right: 30px;

            &-icon {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>


