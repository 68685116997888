<template>
    <div class="record-frame">
        <record-bar :listItemModel="listItemModel" @onButtonClick="onBarButtonClick"></record-bar>
        <div class="record-frame-content">
            <component :is="currentComponent" ref="component" :listItemModel="listItemModel"
                @listItemModelChanged="onListItemModelChanged" @recordPublished="onRecordPublished">
            </component>
        </div>
    </div>
</template>

<script>
import RecordBar from "@c/record-frame/record-bar/record-bar.vue"
import { DateTool } from "@js/date-tool.js";
export default {

    components: {
        RecordBar,
        EstimateRecord: () => import("@c/record-frame/estimate-record/estimate-record.vue"),
        HistoryRecord: () => import("@c/record-frame/history-record/history-record.vue"),
        PressureRecord: () => import("@c/record-frame/pressure-record/pressure-record.vue"),
    },

    data() {
        return {
            id: undefined,
            listItemModel: undefined,
            isGeneratingPDF: false,
            pdfUrl: null,
            loadingPdf: false,
        }
    },

    computed: {
        currentComponent() {
            switch (this.listItemModel?.templateType) {
                case RECORD_TYPE.压力滴定:
                    return "pressure-record"
                case RECORD_TYPE.适宜性评估:
                    return "estimate-record"
                case RECORD_TYPE.病史病历:
                    return "history-record"
                default:
                    return "";
            }
        }
    },

    mounted() {
        this.id = this.$route.query.id
        if (this.id) {
            this.getListItemModel(this.id)
        } else {
            this.listItemModel = {
                patientId: parseInt(this.$route.query.patientId),
                hospitalid: parseInt(this.$route.query.hospitalid),
                templateType: parseInt(this.$route.query.templateType),
                recordState: parseInt(this.$route.query.recordState)
            }
        }
    },
    
    methods: {
        async onBarButtonClick(title) {
            switch (title) {
                case "打印":
                    window.print()
                    break
                case "下载":
                    if (this.isGeneratingPDF) {
                        this.$toast.showRed("PDF报告生成中，请稍侯")
                        return
                    }
                    if (this.listItemModel?.pdfUrl) {
                        window.open(this.listItemModel?.pdfUrl)
                    } else {
                        try {
                            const downloadURL = await this.download(this.listItemModel.id)
                            window.open(downloadURL)
                        } catch (error) {
                            this.$toast.showRed(error)
                        }
                    }
                    break
                case "分享":
                    try {
                        let type = ''
                        let time = ''
                        let name = ''
                        switch (this.listItemModel?.templateType) {
                            case RECORD_TYPE.压力滴定:
                                type = '压力滴定报告'
                                break;
                            case RECORD_TYPE.适宜性评估:
                                type = '适宜性评估报告'
                                break;
                            default:
                                type = ''
                                break;
                        }
                        function statisticsDurationStr(recordModel) {
                            const selectedDurationList = recordModel?.recordDateList
                            if (selectedDurationList && selectedDurationList.length != 0) {
                                //firstDateStr
                                const firstDateStr = `${selectedDurationList[0]}`
                                //lastNextDateStr
                                const lastDate = new Date(selectedDurationList[selectedDurationList.length - 1])
                                const lastNextDayTimestamp = lastDate.setDate(lastDate.getDate() + 1);
                                const lastNextDateStr = `${DateTool.milliTimestampToStr(lastNextDayTimestamp, "YYYY-MM-DD")}`;
                                return `${firstDateStr} ~ ${lastNextDateStr}`
                            } else {
                                return "未选择日期"
                            }
                        }
                        if (this.currentComponent == 'pressure-record') {
                            time = statisticsDurationStr(this.$refs.component.recordModel)
                            name = this.$refs.component.recordModel?.name ?? ''
                        } else if (this.currentComponent == 'estimate-record') {
                            time = statisticsDurationStr(this.$refs.component.recordModel)
                            name = this.$refs.component.recordModel?.name ?? ''
                        }
                        let title = `【NPPV治疗服务${type}${time}${name}】`
                        let downloadURL = ''
                        if (!this.pdfUrl) {
                            this.generatePdfUrl()
                            downloadURL = this.listItemModel?.pdfUrl
                        } else {
                            downloadURL = this.pdfUrl ?? ''
                        }
                        await this.$copyText(title + downloadURL)
                        this.$toast.showGreen("已成功复制到剪贴板");
                    } catch (error) {
                        this.$toast.showRed(error)
                    }
                    break
                default:
                    this.$refs.component.onBarButtonClick(title)
            }
        },

        async onRecordPublished(recoredID) {
            this.isGeneratingPDF = true
            this.listItemModel.pdfUrl = await this.download(recoredID) //调用接口让后台生成PDF快照

            this.isGeneratingPDF = false
        },

        onListItemModelChanged(newListItemModel) {
            this.listItemModel = newListItemModel
            //刷新患者记录列表
            if (window.opener && window.opener.updateRecordList) {
                window.opener.updateRecordList();
            }
        },

        async download(recordID) {
            try {
                const host = process.env.VUE_APP_REPORT_BASE_URL
                const rawURL = window.location.href.replace("http://localhost:8080/", `${host}`)
                const rawHost = decodeURIComponent(rawURL).split("?")[0]
                const rawComponent = decodeURIComponent(rawURL).split("?")[1]
                const component = this.id ? rawComponent : `id=${recordID}&${rawComponent}`
                const currentURL = encodeURI(`${rawHost}?${component}`)
                const param = {
                    url: currentURL,
                    id: recordID
                }
                const downloadURL = await this.$api.downloadRecordPDF(param)
                return downloadURL
            } catch (error) {
                throw error
            }
        },

        async getListItemModel(id) {
            try {
                const param = { id }
                this.listItemModel = await this.$api.getRecordInfo(param)
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        // souece 原字符串 start 要截取的位置 newStr 要插入的字符
        insertStr(source, start, newStr) {
            return source.slice(0, start) + newStr + source.slice(start)
        },

        async generatePdfUrl() {
            if (this.loadingPdf) {
                return
            }
            this.loadingPdf = true
            try {
                const data = await this.$api.generatePdf({
                    url: window.location.href,
                    inline: true
                })
                this.pdfUrl = data
            } catch (error) {

            } finally {
                this.loadingPdf = false
            }
        },
    }


}
</script>

<style lang="scss" scoped>
.record-frame {

    &-content {
        margin-top: 65px;
    }

}
</style>