<template>
    <div class="record-bar">
        <div class="record-bar-left">
            <p class="record-bar-left-title">记录状态：</p>
            <p class="record-bar-left-circle" :class="recordStateClass"></p>
            <p class="record-bar-left-record-type">{{ recordStateStr }}</p>
            <record-date-selector v-show="isShowDatesSelector && isRecordEditable" class="record-bar-left-date-selector"
                :listItemModel="listItemModel" :isEditable="isRecordEditable"
                @onSelectedDates="onDatesSelected"></record-date-selector>
        </div>
        <div class="record-bar-right">
            <button v-for="(item, index) of buttonArray" class="record-bar-right-button" :class="item.class"
                :key="index" @click="onClick(item.title)">{{
                    item.title }} </button>
        </div>
        <record-cancel-alert v-if="isShowCancelAlert" @onCloseClick="onShowCancelCloseClick"
            @onConfirmClick="onShowCancelConfirmClick"></record-cancel-alert>
    </div>
</template>

<script>
import { findKey } from "@js/object-tool.js";
import RecordDateSelector from "@c/record-frame/record-bar/record-date-selector/record-date-selector.vue";
import RecordCancelAlert from "@c/common/alert/panel-alert/panel-alert-record-cancel.vue"

export default {
    components: {
        RecordDateSelector,
        RecordCancelAlert
    },

    props: {
        listItemModel: Object
    },

    data() {
        return {
            buttonArray: [],
            isShowCancelAlert: false,
        }
    },

    computed: {
        recordStateStr() {
            return findKey(RECORD_STATUS, this.listItemModel?.recordState)
        },

        recordStateClass() {
            switch (this.listItemModel?.recordState) {
                case RECORD_STATUS.草稿:
                    return "draft"
                case RECORD_STATUS.已发布:
                    return "published"
                case RECORD_STATUS.已撤销:
                    return "canceled"
                default:
                    break;
            }
        },

        isShowDatesSelector() {
            return this.listItemModel?.templateType != RECORD_TYPE.病史病历
        },

        isRecordEditable() {
            return this.buttonArray.map((item) => { return item.title }).includes("暂存")//打印、下载、暂存、发布
        },
    },

    mounted() {
        this.setupButtonArray()
    },

    watch: {
        listItemModel() {
            this.setupButtonArray()
        }
    },

    methods: {
        onClick(title) {
            switch (title) {
                case "撤销":
                    this.isShowCancelAlert = true
                    return
                case "编辑":
                    this.buttonArray = [{
                        title: "打印",
                        class: "normal"
                    }, {
                        title: "下载",
                        class: "normal"
                    }, {
                        title: "暂存",
                        class: "normal"
                    }, {
                        title: "发布",
                        class: "blue"
                    }]
                    break
                case "下载":
                    if (this.isRecordEditable) {
                        this.$message({
                            message: '请先暂存或发布后再下载',
                            offset: 2
                        });
                        return
                    }
                    break
                default:
                    break
            }
            this.$emit("onButtonClick", title)
        },

        //cancel-alert
        onShowCancelConfirmClick() {
            this.isShowCancelAlert = false
            this.$emit("onButtonClick", "撤销")
        },

        onShowCancelCloseClick() {
            this.isShowCancelAlert = false
        },

        //record-date-selector
        onDatesSelected(selectedDatesArray) {
            const processedSelectedDatesArray = selectedDatesArray.sort() //从小到大，因为还要涉及展示
            this.$store.commit("saveSelectedRecordDurationModel", {
                changedBySelector: true,
                selectedDatesArray: processedSelectedDatesArray,
            });
        },

        setupButtonArray() {
            switch (this.listItemModel?.recordState) {
                case RECORD_STATUS.草稿:
                    if (this.listItemModel?.id) {
                        this.buttonArray = [{
                            title: "打印",
                            class: "normal"
                        }, {
                            title: "下载",
                            class: "normal"
                        }, {
                            title: "编辑",
                            class: "blue-border"
                        }]
                    } else {
                        this.buttonArray = [{
                            title: "打印",
                            class: "normal"
                        }, {
                            title: "下载",
                            class: "normal"
                        }, {
                            title: "暂存",
                            class: "normal"
                        }, {
                            title: "发布",
                            class: "blue"
                        }]
                    }
                    break
                case RECORD_STATUS.已发布:
                    this.buttonArray = [{
                        title: "打印",
                        class: "normal"
                    }, {
                        title: "下载",
                        class: "normal"
                    }, {
                        title: "撤销",
                        class: "red-border"
                    }]
                    if (this.listItemModel?.templateType != RECORD_TYPE.病史病历) {
                        this.buttonArray.push({
                            title: "分享",
                            class: "blue"
                        })
                    }
                    break
                case RECORD_STATUS.已撤销:
                    this.buttonArray = [{
                        title: "打印",
                        class: "normal"
                    }, {
                        title: "下载",
                        class: "normal"
                    }]
                    break
                default:
                    break;
            }
        }
    }

}
</script>

<style lang="scss">
.btnConfirm {
    background-color: pink !important;
}
</style>

<style lang="scss" scoped>
@media print {
    .record-bar {
        display: none !important;
    }
}

.record-bar {
    @include z-index-max;
    position: fixed;
    top: 0;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    width: 100%;
    min-height: 65px;
    padding: 0px 40px 0px 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
        display: flex;
        align-items: center;

        &-title {
            color: black;
            font-size: 16px;
        }

        &-circle {
            width: 8px;
            height: 8px;
            border-radius: 4px;
            margin-left: 8px;
            margin-right: 7px;
        }

        &-date-selector {
            margin-left: 100px;
        }

        &-duration-selector {
            margin-left: 100px;
            display: flex;
            align-items: center;
        }
    }

    &-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-button {
            font-size: 16px;
            width: 70px;
            height: 40px;
            border-radius: 3px;
            margin-left: 8px;
        }
    }
}

.draft {
    background-color: #0052D9;
}

.published {
    background-color: #00A870;
}

.canceled {
    background-color: #E05252;
}

.normal {
    color: rgba(0, 0, 0, 0.9);
    background-color: white;
    border: 1px solid #979797;
}

.blue {
    color: white;
    background-color: #5889EB;
    border: 1px solid #5889EB;
}

.blue-border {
    color: #5889EB;
    background-color: white;
    border: 1px solid #5889EB;
}

.red-border {
    color: #E05252;
    background-color: white;
    border: 1px solid #E05252;
}
</style>