<template>
    <div class="date-count-label">
        <p v-if="count" class="date-count-label-count">共选择{{ count }}天</p>
        <p v-else class="date-count-label-placeholder">日期</p>
    </div>
</template>

<script>
export default {
    props: {
        count: Number
    }

}
</script>

<style lang="scss" scoped>
.date-count-label {
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: white;
    width: 200px;
    height: 38px;
    display: flex;
    align-items: center;
    pointer-events: none;


    &-placeholder {
        color: rgba($color: #000000, $alpha: 0.4);
        font-size: 16px;
        margin-left: 30px;
        pointer-events: none;
    }

    &-count {
        background-color: #E7E7E7;
        width: 93px;
        height: 24px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30px;
        pointer-events: none;
    }

}
</style>