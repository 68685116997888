
import { DateTool } from "@js/date-tool.js";
function getWeekDayArray(year, month) { //传入  年  月  获取当月有几周 以及日期 
    var new_year = year;    //取当前的年份
    var new_month = month++;//取下一个月的第一天，方便计算（最后一天不固定）
    if (new_month < 10) { new_month = '0' + new_month }
    var weekDay = ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期天"];
    if (new_month > 12) {
        new_month -= 12;        //月份减
        new_year++;            //年份增
    }
    var first_date = new Date(new_year, new_month, 1);                //取当年当月中的第一天-时间格式
    var last_Data = (new Date(first_date.getTime() - 1000 * 60 * 60 * 24)).getDate() //获取当月最后一天日期
    //当月第一天是周几
    var firstzhouji = new Date(new_year + '/' + new_month + '/' + 1).getDay() == 0 ? '星期天' : weekDay[new Date(new_year + '/' + new_month + '/' + 1).getDay() - 1]
    //当月最后一天是周几
    var lastzhouji = new Date(new_year + '/' + new_month + '/' + last_Data).getDay() == 0 ? '星期天' : weekDay[new Date(new_year + '/' + new_month + '/' + last_Data).getDay() - 1]
    var firsttime = '' //第一周有几天
    if (firstzhouji == '星期一') { firsttime = 7 }
    if (firstzhouji == '星期二') { firsttime = 6 }
    if (firstzhouji == '星期三') { firsttime = 5 }
    if (firstzhouji == '星期四') { firsttime = 4 }
    if (firstzhouji == '星期五') { firsttime = 3 }
    if (firstzhouji == '星期六') { firsttime = 2 }
    if (firstzhouji == '星期天') { firsttime = 1 }

    var lasttime = '' //最后一周有几天
    if (lastzhouji == '星期一') { lasttime = 1 }
    if (lastzhouji == '星期二') { lasttime = 2 }
    if (lastzhouji == '星期三') { lasttime = 3 }
    if (lastzhouji == '星期四') { lasttime = 4 }
    if (lastzhouji == '星期五') { lasttime = 5 }
    if (lastzhouji == '星期六') { lasttime = 6 }
    if (lastzhouji == '星期天') { lasttime = 7 }

    // 前后两周  加上 剩余周数  得出总周数
    var contime = 2 + (last_Data - lasttime - firsttime) / 7

    //得出每周对应的日期
    var zhouArry = []

    for (var i = 1; i <= contime; i++) {
        var strTime = ''
        var lastTime = ''
        if (i == 1) {
            strTime = year + '-' + new_month + '-' + '01'
            let aa = 1 + firsttime - 1
            if (aa < 10) {
                aa = '0' + aa
            }
            lastTime = year + '-' + new_month + '-' + aa
        } else if (i == contime) {
            let bb = last_Data - lasttime + 1
            if (bb < 10) {
                bb = '0' + bb
            }
            strTime = year + '-' + new_month + '-' + bb
            lastTime = year + '-' + new_month + '-' + (last_Data < 10 ? ('0' + last_Data) : last_Data)
            lastTime = year + '-' + new_month + '-' + last_Data
        } else {
            strTime = addDate(zhouArry[zhouArry.length - 1].endTime, 1)
            lastTime = addDate(zhouArry[zhouArry.length - 1].endTime, 7)
        }

        zhouArry.push(
            {
                weeknum: i,
                beginTime: strTime,
                endTime: lastTime,
                weekDays: getDayArray(strTime, lastTime)
            })


        //日期增加 接受两个参数， 传入的时间，传入时间增加的天数
        function addDate(date, days) {
            if (days == undefined || days == '') {
                days = 1;
            }
            var date = new Date(date);
            date.setDate(date.getDate() + days);
            var month = date.getMonth() + 1;
            if (month < 10) { month = '0' + month; }
            var day = date.getDate();
            if (day < 10) { day = '0' + day; }
            return date.getFullYear() + '-' + month + '-' + day;
        }
    }
    return zhouArry
}

function getWeekDayDayArray(year, month) {
    let weekDayData = []
    //1:3 2:4 3:5 4:6 5:0 6:1 7:2
    //0:3 1:4 2:5 3:6 4:0 5:1 6:2
    for (let index = 0; index < 7; index++) {
        const weekIndex = index < 4 ? index + 3 : index - 4
        let allDay = new Date(year, month, 0).getDate() //获取当月总天数
        let weekDay = [];
        for (let i = 1; i <= allDay; i++) {
            let week = new Date(year, month, i).getDay()
            const processedMonth = month < 10 ? '0' + month : month
            const processedDay = i < 10 ? '0' + i : i
            let str = `${year}-${processedMonth}-${processedDay}`
            if (week == weekIndex) {
                weekDay.push(str)
            }
        }
        weekDayData.push(weekDay)
    }
    //有的时候会以周日为初始日，需要手动调整
    let processedWeekDayData = weekDayData
    const firstDate = new Date(weekDayData[0][0])
    if (firstDate.getDay() == 0) {
        processedWeekDayData.push(processedWeekDayData.shift()) //数组第一个元素移至末尾
    }
    return processedWeekDayData
}

function getAllMonthDayArray(year, month) {
    const firstDate = `${year}-${month}-1`;
    let lastDay = new Date(year, month, 0).getDate() //获取当月总天数
    const lastDate = `${year}-${month}-${lastDay}`
    return getDayArray(firstDate, lastDate);
}

function getDayArray(begin, end) {
    var dateAllArr = new Array();
    var ab = begin.split("-");
    var ae = end.split("-");
    var db = new Date();
    db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
    var de = new Date();
    de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
    var unixDb = db.getTime();
    var unixDe = de.getTime();
    for (var k = unixDb; k <= unixDe;) {
        const newDate = new Date(parseInt(k))
        const newDateStr = DateTool.dateToStr(newDate, "YYYY-MM-DD")
        dateAllArr.push(newDateStr);
        k = k + 24 * 60 * 60 * 1000;
    }
    return dateAllArr;
}

export {
    getAllMonthDayArray,
    getWeekDayArray,
    getWeekDayDayArray
}