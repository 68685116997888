<template>
    <div class="week-selector">
        <div class="week-selector-content">
            <div class="week-selector-content-item" v-for="(item, index) of dataArray" :key="index"
                @click="onItemClick(index)">
                <img class="week-selector-content-item-icon" :src="item ? checkedIcon : unCheckedIcon" />
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            dataArray: [false, false, false, false, false, false, false],
            checkedIcon: require("@imgs/report-check-blue.png"),
            unCheckedIcon: require("@imgs/report-check-white.png"),
        };
    },

    methods: {
        onItemClick(index) {
            let tempDataArray = this.dataArray
            tempDataArray[index] = !tempDataArray[index]
            this.dataArray = [...tempDataArray]
            if (index == 0) {
                this.$emit("onSelectedAll", this.dataArray[index]);
            } else {
                this.$emit("onSelectedWeek", index, this.dataArray[index]);
            }
        },

        setup(dataArray){
            this.dataArray = dataArray
        }
    },
};
</script>

<style lang="scss" scoped>
.week-selector {
    &-content {
        margin-left: 28px;
        margin-top: 88px;

        :first-child {
            margin-bottom: 68px !important;
        }

        &-item {
            width: 16px;
            height: 16px;
            margin-bottom: 21.5px;
            display: flex;
            align-items: center;



            &-icon {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>


