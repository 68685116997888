<template>
    <div class="record-date-selector">
        <p class="record-date-selector-title">请选择日期：</p>
        <el-date-picker popper-class="record-date-selector-panel" id="picker" ref="picker" type="dates"
            :append-to-body="false" v-model="dates" value-format="yyyy-MM-dd" placeholder="选择一个或多个日期"
            :pickerOptions="pickerOptions" :default-value="defalutDate" :clearable="isEditable" @focus="onFocus"
            @change="onChange">
        </el-date-picker>
    </div>
</template>

<script>
import { DatePicker } from "element-ui";
import { DateTool } from "@js/date-tool.js";
import { getAllMonthDayArray, getWeekDayArray, getWeekDayDayArray } from "./record-date-selector-tool";
import DateCountLabel from "./date-count-label.vue";
import WeekDaySelector from "./weakday-selector.vue";
import WeekSelector from "./week-selector.vue";
import Vue from 'vue'
export default {
    components: {
        elDatePicker: DatePicker,
    },

    props: {
        listItemModel: Object,
        isEditable: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            //展示相关
            pickerOptions: {
                disabledDate: date => {
                    var year = date.getFullYear();
                    return year < 2022
                },
                firstDayOfWeek: 1,
                cellClassName: (time) => {
                    const dateStr = DateTool.milliTimestampToStr(time.getTime(), "YYYY-MM-DD");
                    const isIncludeBloodOxygen =
                        this.bloodOxygenReportDateArray.includes(dateStr);
                    const isIncludeBreath = this.breathReportDateArray.includes(dateStr);
                    if (isIncludeBloodOxygen && !isIncludeBreath) {
                        return "blood-oxygen-date";
                    }
                    if (!isIncludeBloodOxygen && isIncludeBreath) {
                        return "breath-date";
                    }
                    if (isIncludeBloodOxygen && isIncludeBreath) {
                        return "blood-oxygen-date breath-date";
                    }
                },
            },
            insertElement: false,
            dateCountLabel: undefined,
            weekDaySelector: undefined,
            weekSelector: undefined,

            bloodOxygenReportDateArray: [],
            breathReportDateArray: [],
            defalutDate: undefined,

            currentYear: undefined,
            currentMonth: undefined,
            monthAllDayArray: [],
            weekDayArray: [],
            weekDayDayArray: [],

            dates: [],

        }
    },

    computed: {
        selectedRecordDurationModel() {
            return this.$store.state.selectedRecordDurationModel
        }
    },

    mounted() {
        this.createDateCountLabel()
    },

    watch: {
        selectedRecordDurationModel(value) {
            if (value) {
                this.dates = value.selectedDatesArray
                this.count = this.dates?.length
            }
        },

        listItemModel() {
            this.getDotInfo()
        },

        dates(value) {
            if (value) {
                this.updateSelector()
                this.dateCountLabel.count = value.length
            } else { //清空
                this.dates = []
            }
        }
    },

    methods: {
        onSelectedAll(isSelected) {
            this.updateDates(this.monthAllDayArray, isSelected)
        },

        onSelectedWeekday(index, isSelected) {
            const weekDayDays = this.weekDayDayArray[index]
            this.updateDates(weekDayDays, isSelected)
        },

        onSelectedWeek(index, isSelected) {
            if (index > this.weekDayArray.length) { return }
            const weekDays = this.weekDayArray[index - 1]
            this.updateDates(weekDays, isSelected)
        },

        onChange() {
            let dates = Array.from(new Set(this.dates)) //去重
            this.$emit("onSelectedDates", dates)
        },

        async onFocus() {
            //面板出现
            if (!this.insertElement) {
                await this.$nextTick()
                if (this.isEditable) {
                    this.createWeekDaySelector()
                    this.createWeekSelector()
                } else {
                    this.hideBottomBar()
                }
                this.insertElement = true
            }
            this.getCurrentMonthAndYear()
            this.monitorCalenderChange()
        },

        createDateCountLabel() {
            const input = this.$refs.picker.$el.querySelector('.el-input__inner');
            const dateCountLabelContrustor = Vue.extend(DateCountLabel);
            const dateCountLabel = new dateCountLabelContrustor();
            const dateCountLabelContainer = document.createElement('div')
            input.before(dateCountLabelContainer);
            dateCountLabel.$mount(dateCountLabelContainer)
            this.dateCountLabel = dateCountLabel
        },

        createWeekDaySelector() {
            const targetEl1 = this.$refs.picker.picker.$el.querySelector('.el-picker-panel__body');
            const weekDaySelectorContrustor = Vue.extend(WeekDaySelector);
            const weekDaySelector = new weekDaySelectorContrustor();
            weekDaySelector.$on('onSelectedWeekday', this.onSelectedWeekday)
            const weekDaySelectorContainer = document.createElement('div')
            targetEl1.insertBefore(weekDaySelectorContainer, targetEl1.children[1]);
            weekDaySelector.$mount(weekDaySelectorContainer)
            this.weekDaySelector = weekDaySelector
        },

        createWeekSelector() {
            const targetEl2 = this.$refs.picker.picker.$el.querySelector('.el-picker-panel__body-wrapper');
            const weekSelectorContrustor = Vue.extend(WeekSelector);
            const weekSelector = new weekSelectorContrustor();
            weekSelector.$on('onSelectedAll', this.onSelectedAll)
            weekSelector.$on('onSelectedWeek', this.onSelectedWeek)
            const weekSelectorContainer = document.createElement('div')
            targetEl2.insertBefore(weekSelectorContainer, targetEl2.children[0]);
            weekSelector.$mount(weekSelectorContainer)
            this.weekSelector = weekSelector
        },

        hideBottomBar() {
            const targetEl2 = this.$refs.picker.picker.$el.querySelector('.el-picker-panel__footer');
            targetEl2.style.display = 'none'
        },

        monitorCalenderChange() {
            document.querySelectorAll("[aria-label='下个月'],[aria-label='上个月'],[aria-label='后一年'],[aria-label='前一年']")
                .forEach(item => item.addEventListener('click', () => {
                    this.getCurrentMonthAndYear()
                    this.updateSelector()
                }))
        },

        async getCurrentMonthAndYear() {
            let year, month
            year = document.querySelectorAll('.el-date-picker__header-label')[0].innerHTML.slice(0, 4)
            month = document.querySelectorAll('.el-date-picker__header-label')[1].innerHTML.slice(0, 2)
            this.currentMonth = month.trim()
            this.currentYear = year
            this.monthAllDayArray = getAllMonthDayArray(this.currentYear, this.currentMonth)
            this.weekDayArray = getWeekDayArray(this.currentYear, this.currentMonth).map((item) => { return item.weekDays })
            this.weekDayDayArray = getWeekDayDayArray(this.currentYear, this.currentMonth)
        },

        updateDates(dateArray, isSelected) {
            if (isSelected) {
                let dates = [...this.dates]
                let rawPlusedDates = dates.concat(dateArray)
                let plusedDates = Array.from(new Set(rawPlusedDates)) //去重
                this.dates = plusedDates
            } else {
                let minusedDates = this.dates.filter((item) =>
                    !dateArray.some((element) => element === item)
                );
                this.dates = minusedDates
            }
        },

        updateSelector() {
            if (!this.weekSelector || !this.weekDaySelector) { return }
            //weekday-selector
            //isSelectedAllMonthDate
            const intersectionArray = this.dates.filter((item) => {
                return this.monthAllDayArray.indexOf(item) > -1
            })
            const isSelectedAllMonthDate = intersectionArray.length == this.monthAllDayArray.length
            //weekSelectStatusArray
            const weekSelectStatusArray = this.weekDayArray.map(dayArray => {
                const intersectionArray = this.dates.filter((item) => {
                    return dayArray.indexOf(item) > -1
                })
                return intersectionArray.length == dayArray.length
            })
            const weekSelectorDataArray = [isSelectedAllMonthDate].concat(weekSelectStatusArray)
            this.weekSelector.setup(weekSelectorDataArray)
            //week-day-selector
            const weekDaySelectStatusArray = this.weekDayDayArray.map(dayArray => {
                const intersectionArray = this.dates.filter((item) => {
                    return dayArray.indexOf(item) > -1
                })
                return intersectionArray.length == dayArray.length
            })
            const weekDaySelectorDataArray = weekDaySelectStatusArray
            this.weekDaySelector.setup(weekDaySelectorDataArray)

        },

        async getDotInfo() {
            if (!this.listItemModel) { return }
            this.bloodOxygenReportDateArray = await this.getBloodOxygenReportDateList();
            this.breathReportDateArray = await this.getBreathReportDateList();
            const bloodOxygenLatestDate = this.bloodOxygenReportDateArray[0]
            const breathLatestDate = this.breathReportDateArray[this.breathReportDateArray.length - 1]
            if (bloodOxygenLatestDate && breathLatestDate) {
                if (bloodOxygenLatestDate > breathLatestDate) {
                    this.defalutDate = new Date(bloodOxygenLatestDate)
                } else {
                    this.defalutDate = new Date(breathLatestDate)
                }
            } else if (bloodOxygenLatestDate) {
                this.defalutDate = new Date(bloodOxygenLatestDate)
            } else if (breathLatestDate) {
                this.defalutDate = new Date(breathLatestDate)
            }
        },

        async getBloodOxygenReportDateList() {
            try {
                const params = {
                    patientId: this.listItemModel.patientId,
                };
                return await this.$api.getBloodOxygenReportDateList(params);
            } catch (error) {
                return []
            }
        },

        async getBreathReportDateList() {
            try {
                const params = {
                    uid: this.listItemModel.patientId,
                    hospitalId: this.listItemModel.hospitalid,
                };
                return await this.$api.getReportDateList(params);
            } catch (error) {
                return []
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.record-date-selector {
    display: flex;
    align-items: center;
}
</style>

<style lang="scss">
.el-date-picker {
    width: 390px;
}

//仅用年月点击事件
.el-date-picker__header-label {
    pointer-events: none;
}

.el-picker-panel__body-wrapper {
    display: flex !important;
}

.el-date-picker .el-picker-panel__content {
    width: auto
}

.el-date-table th {
    text-align: center;
}

.el-date-table td.selected div {
    background-color: white;
}

.el-date-table td.selected span {
    background-color: #ECF2FE;
    color: black;
    border-radius: 3px;
}

.el-picker-panel__footer {
    padding-right: 20px;
}
</style>

<style lang="scss">
.blood-oxygen-date>div ::after {
    content: "";
    position: absolute;
    right: 1px;
    top: 2px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #ff6d4a;
}

.breath-date>div ::before {
    content: "";
    position: absolute;
    left: 1px;
    top: 2px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #4da0fb;
}

.el-date-table td.current:not(.disabled) span {
    color: #606266;
    background-color: #edf5ff;
    border-radius: 5px;
}
</style>
